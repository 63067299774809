import { userUtils, utils } from "../helpers";

// When enabled, clients can trigger sms optin campaigns
export const SHOW_TEXT_OPTIN_CAMPAIGN_TYPE = ['1062', '1099', '1159', '1002', '1243', '1653', '2182', '1345', '2932', '1352', '3384', '4125'];

const client_hacks = {
    SHOW_TEXT_OPTIN_CAMPAIGN_TYPE
} as const;

export type ClientHackKey = keyof typeof client_hacks;

export function hasClientHack(key: ClientHackKey, uid?: string): boolean {
    if (utils.isDevUID() && userUtils.debugMode()) return true;
    return client_hacks[key].includes(uid || utils.uid);
}

