import { PixelPopupWidgetTriggerType } from '@/types';

export type PixelPopupWidgetTriggerConfig = {
	label: string;
	short: string;
	tooltip: string;
	showThreshold: boolean;
	showDelay: boolean;
	thresholdSuffix: string;
	thresholdPrefix: string;
	actionDelaySuffix: string;
	actionDelayPrefix: string;
	thresholdTooltip: string;
	actionDelayTooltip: string;
};
export const PopupWidgetTriggerMap: Record<PixelPopupWidgetTriggerType, PixelPopupWidgetTriggerConfig> = {
	onLoad: {
		tooltip: 'When set the popup will display when the page loads',
		label: 'Show on page load',
		short: 'load',
		showThreshold: false,
		showDelay: true,
		thresholdSuffix: '',
		thresholdPrefix: '',
		actionDelayPrefix: 'Show',
		actionDelaySuffix: 'seconds after page load',
		thresholdTooltip: '',
		actionDelayTooltip: 'When set the popup will not display until x seconds after page load',
	},
	onClick: {
		tooltip: 'When set the popup will display when the user clicks anywhere on the page',
		label: 'Show on click',
		short: 'click',
		showThreshold: true,
		showDelay: true,
		thresholdSuffix: 'clicks',
		thresholdPrefix: 'Show after',
		actionDelayPrefix: 'Show',
		actionDelaySuffix: 'seconds after click',
		thresholdTooltip: 'When set the popup will not display until the user has clicked x times',
		actionDelayTooltip: 'When set the popup will not display until x seconds after click',
	},
	onScroll: {
		tooltip: 'When set the popup will display when the user scrolls the page',
		label: 'Show on scroll',
		short: 'scroll',
		showThreshold: false,
		showDelay: true,
		thresholdPrefix: 'Show after scrolling',
		thresholdSuffix: '% of the page',
		actionDelayPrefix: 'Show',
		actionDelaySuffix: 'seconds after scroll',
		thresholdTooltip: 'When set the popup will not display until the user has scrolled x% of the page',
		actionDelayTooltip: 'When set the popup will not display until x seconds after scrolling',
	},
	onExit: {
		tooltip: 'When set the popup will display when the user is about to exit the page',
		label: 'Show on exit',
		short: 'exit',
		showThreshold: false,
		showDelay: false,
		thresholdSuffix: '',
		thresholdPrefix: '',
		actionDelayPrefix: 'Show',
		actionDelaySuffix: 'seconds after exit',
		thresholdTooltip: '',
		actionDelayTooltip: 'When set the popup will not display until x seconds after exit',
	},
};
